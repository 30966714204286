<template lang="pug">
  .changeOrder
    project-facade(v-if="changeOrder", active-tab="changeOrder", :project-id="changeOrder.project.id" )
    change-order-form(
      v-model='changeOrder',
      :loading='$apollo.loading',
      :saving='saving',
      submit-label='Update change order',
      @save='updateChangeOrder',
      @cancel='cancelUpdate')
</template>

<script>
import updateChangeOrderMutation from "@/graphql/mutations/finance/change_orders/UpdateChangeOrder.gql";
import updateChangeOrderFilter from "@/graphql/mutations/filters/UpdateChangeOrderFilter";
import changeOrderQuery from "@/graphql/queries/finance/ChangeOrder.gql";
import mutate from "@/components/utils/Mutator";
import ChangeOrderForm from "./Form.vue";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    changeOrder: {
      query: changeOrderQuery,
      variables() {
        return { id: this.$route.params.id };
      }
    }
  },
  components: {
    ChangeOrderForm,
    ProjectFacade
  },
  data() {
    return {
      saving: false,
      changeOrder: null
    };
  },
  methods: {
    updateChangeOrder() {
      const { project } = this.changeOrder;
      const vm = this;

      this.saving = true;

      return mutate(this, {
        mutation: updateChangeOrderMutation,
        variables: {
          input: {
            ...updateChangeOrderFilter(this.changeOrder)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/projects/${project.id}/change_orders/${data.data.updateChangeOrder.changeOrder.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelUpdate() {
      window.location.assign(
        `/projects/${this.changeOrder.project.id}/change_orders/${this.changeOrder.id}`
      );
    }
  }
};
</script>
